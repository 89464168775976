<template>
  <div
    id="occupation"
    class="page-container"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm danh mục nghề nghiệp'"
      :arrayExcel="arrayExcel"
      :hideSearch="false"
      :hideDelete="false"
      @clickDowloadSample="downloadExampleFileOccupation"
      @clickExportExcel="downloadFileOccupation"
      @importFile="importFileExcel($event)"
      @clickAdd="handleOpenModalCreate"
    />

    <div class="permission-content">
      <liqui-tree
        ref="tree"
        class="tree-mini"
        :data="dataListOccupation"
        :options="getTreeOption"
      >
        <div
          slot-scope="{ node }"
          class="tree-text d-flex align-items-center"
          @mouseleave="mouseOutItem"
        >
          <template>
            <span
              @click.stop
            >
              <div class="d-flex align-items-center">
                <span class="flex items-center">
                  <span class="tree-text">{{ node.text }}</span>
                  <b-dropdown
                    v-if="treeOptions.type==='normal'"
                    class="node-action ml-1"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('add')===true"
                      @click="handleOpenModal(node.id)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span>Thêm mới</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('add-from-file')===true"
                    >
                      <feather-icon
                        icon="FilePlusIcon"
                        class="mr-50"
                      />
                      <span>Thêm từ file</span>
                    </b-dropdown-item> -->
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('edit')===true"
                      @click="handleOpenDetail(node.id)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Chỉnh sửa  </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('delete')===true"
                      @click="handleOpenModalDelete(node.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Xóa</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </div>
            </span>
          </template>
        </div>
      </liqui-tree>
    </div>

    <!-- Modal tạo cơ cấu tổ chức mới -->
    <modal-create-occupation
      idModalCreate="modalCreate"
      :parentId="parentId"
      :type="modalType"
      :dataOccupationId="dataOccupationId"
      @handleCreateOccupation="handleCreateOccupation"
    />

    <!-- modal xóa người dùng-->
    <modal-delete-occupation
      idModalDelete="confirmModalId"
      :deletedOccupationId="deletedOccupationId"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'

import LiquiTree from 'liquor-tree'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ModalCreateOccupation from './components/ModalCreateOccupation.vue'
import ModalDeleteOccupation from './components/ModalDeleteOccupation.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BButton,
    LiquiTree,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    ModalCreateOccupation,
    ModalDeleteOccupation,
    VBTooltip,
    BBadge,
    ButtonAllHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      treeOptions: {
        type: 'normal',
        action: [
          'add',
          'add-from-file',
          'edit',
          'delete',
        ],
      },
      parentId: '',
      modalContent: '',
      deletedOccupationId: '',
      modalType: '',
      idMinus: '',
      isMinus: false,
      arrayExcel: [
        'level1',
        'level2',
        'level3',
        'level4',
        'level5',
        'name',
        'parentId',
        'description',
        'parentIdId',
      ],
      dataOccupationId: {},
    }
  },
  computed: {
    ...mapGetters('occupation', ['dataListOccupation', 'dataOccupationChildren']),
    getTreeOption() {
      return {
        // minFetchDelay: 200,
        fetchData: node => this.fetchNode(node),
        propertyNames: {
          text: 'name',
          children: 'childrens',
        },
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('occupation', [
      'getApiExcelOccupation',
      'downloadExampleFileOccupation',
      'downloadFileOccupation',
      'doFetchListOccupation',
      'doFetchOccupationChildren',
    ]),

    // di chuyển chuột khỏi nhánh
    mouseOutItem(event) {
      const treeContent = event.target.closest('.tree-content')
      const els = treeContent.getElementsByClassName('show')
      if (els.length > 0) {
        for (let i = els.length - 1; i >= 0; i -= 1) {
          els[i].classList.remove('show')
        }
      }
    },

    // danh sách dữ liệu từ node được chọn đến gốc
    async fetchData() {
      const model = {
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchListOccupation(model)
      this.$nextTick(() => {
        this.dataListOccupation.forEach(element => {
          element.isBatch = true
        })
        this.$refs.tree.setModel(this.dataListOccupation)
      })
    },
    recursiveData(data) {
      data.state = {
        checked: false,
        expanded: false,
      }
      data.data = {
        selectOnly: true,
      }
      if (this.value.find(x => x === data.id)) {
        data.state.checked = true
      }
      if (data.childrens && data.childrens.length > 0) {
        data.state.expanded = true
        // data.isBatch = false
        data.childrens.forEach(element => {
          this.recursiveData(element)
        })
      }
    },

    // lấy dữ liệu node con cơ cấu tổ chức
    async fetchNode(node) {
      const model = {
        id: node.id,
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchOccupationChildren(model)
      this.dataOccupationChildren.forEach(element => {
        element.isBatch = true
      })
      return new Promise(resolve => {
        resolve(this.dataOccupationChildren)
      })
    },

    async handleOpenDetail(id) {
      this.modalType = 'edit'
      this.parentId = id
      this.$bvModal.show('modalCreate')
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_OCCUPATION}${this.parentId}`)
      this.dataOccupationId = data
    },

    // Mở modal
    async handleOpenModal(id) {
      this.parentId = id
      this.modalType = 'add'
      this.$bvModal.show('modalCreate')
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_OCCUPATION}${this.parentId}`)
      this.dataOccupationId = data
    },

    // Mở modal taoj mowis item
    handleOpenModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show('modalCreate')
    },

    // Mở modal xóa
    handleOpenModalDelete(id) {
      this.deletedOccupationId = id
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show('confirmModalId')
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async handleCreateOccupation(val, validate) {
      if (this.modalType === 'edit') {
        const payload = {
          ...val,
          id: this.parentId,
        }
        validate.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.put(ConstantsApi.EDIT_OCCUPATION, payload).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Cập nhật thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.fetchData()
                this.$bvModal.hide('modalCreate')
                val.level1 = ''
                val.level2 = ''
                val.level3 = ''
                val.level4 = ''
                val.level5 = ''
                val.name = ''
                val.parentId = ''
                val.description = ''
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      }
      if (this.modalType === 'add') {
        const data = {
          ...val,
        }
        validate.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.CREATE_OCCUPATION, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$bvModal.hide('modalCreate')
                val.level1 = ''
                val.level2 = ''
                val.level3 = ''
                val.level4 = ''
                val.level5 = ''
                val.name = ''
                val.parentId = ''
                val.description = ''
                this.fetchData()
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      }
    },

    // Action tạo mới cơ cấu tổ chức
    async deleteAction(val, validate) {
      const modal = {
        ids: this.deletedOccupationId,
        organizationStructureId: val,
      }
      validate.validate().then(async success => {
        if (success) {
          this.$showAllPageLoading()
          await axiosApiInstance.delete(ConstantsApi.DELETE_OCCUPATION, { params: modal }).then(res => {
            if (res.status === 200) {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast('Xóa thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              this.$bvModal.hide('confirmModalId')
              this.fetchData()
            } else {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            }
          })
        }
      })
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },

    // kiểm tra hợp lệ từ file exel
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcelOccupation(model)
      await this.$router.push({ name: 'import-occupation' })
    },
  },
}
</script>

<style lang="scss" scope>
@import "@/assets/scss/tree/tree.scss";

#occupation {
  .button-import {
    .input-file {
      display: none;
    }
  }
  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }
  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }
  .search-input {
    width: 400px;
  }

  .permission-content {
    border: 1px solid #A6A8B0;
    box-sizing: border-box;
    border-radius: 6px;
    height: 100%;
    .items-center {
      .dropdown-menu {
        margin-top: 0.2rem !important;
      }
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
