<template>
  <b-modal
    :id="idModalCreate"
    size="lg"
    :title="type==='add'?'Thêm danh mục nghề nghiệp':'Chỉnh sửa danh mục nghề nghiệp'"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateOccupation"
    @hidden="resetState"
  >
    <b-row class="mb-1">
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <label for="InputHelp">Mã cấp 1</label>
        <b-form-input
          id="InputHelp"
          v-model="queryBody.level1"
        />
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <label for="InputHelp">Mã cấp 2</label>
        <b-form-input
          id="InputHelp"
          v-model="queryBody.level2"
        />
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <label for="InputHelp">Mã cấp 3</label>
        <b-form-input
          id="InputHelp"
          v-model="queryBody.level3"
        />
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <label for="InputHelp">Mã cấp 4</label>
        <b-form-input
          id="InputHelp"
          v-model="queryBody.level4"
        />
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <label for="InputHelp">Mã cấp 5</label>
        <b-form-input
          id="InputHelp"
          v-model="queryBody.level5"
        />
      </b-col>
    </b-row>

    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-form-group>
          <label for="InputHelp">Tên danh mục nghề nghiệp <span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="customCode"
          >
            <b-form-input
              id="InputHelp"
              v-model="queryBody.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

    <b-form-group>
      <label for="InputHelp">Danh mục nghề nghiệp cha </label>
      <occupation-select
        v-model="queryBody.parentId"
        :parentId="parentId"
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Nội dung</label>
      <b-form-textarea
        id="InputHelp"
        v-model="queryBody.description"
        rows="3"
        no-resize
      />
    </b-form-group>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import OccupationSelect from './OccupationSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    OccupationSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalCreate: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataOccupationId: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      queryBody: {
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        name: null,
        parentId: null,
        description: null,
      },
      dataDetailStrcut: null,
      customCode: {
        required: 'Tên danh mục nghề nghiệp là bắt buộc',
      },
    }
  },
  watch: {
    dataOccupationId: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            level1: this.dataOccupationId.level1,
            level2: this.dataOccupationId.level2,
            level3: this.dataOccupationId.level3,
            level4: this.dataOccupationId.level4,
            level5: this.dataOccupationId.level5,
            name: this.dataOccupationId.name,
            parentId: this.dataOccupationId.parentId,
            description: this.dataOccupationId.description,
          }
        }
        // if (val && this.type === 'add') {
        //   this.queryBody = {
        //     level1: null,
        //     level2: null,
        //     level3: null,
        //     level4: null,
        //     level5: null,
        //     name: null,
        //     parentId: this.dataOccupationId.id,
        //     description: null,
        //   }
        // }
      },
    },
  },
  methods: {
    // ...mapActions('struct', ['doCreateOrganizationStruct']),
    handleCreateOccupation(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('handleCreateOccupation', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.queryBody = {
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        name: null,
        parentId: null,
        description: null,
      }
    },
  },
}
</script>

<style>

</style>
